import React from 'react';

interface FontOption {
  name: string;
  displayName: string;
  style: string;
  previewText?: string;
}

interface SubtitleFontSelectorProps {
  currentFont: string;
  onFontChange: (font: string) => void;
}

const fontOptions: FontOption[] = [
  { 
    name: "CheGuevaraBarry-Brown",
    displayName: "Guevara",
    style: "font-che-guevara",
    previewText: "Abc"
  },
  { 
    name: "FiraSansCondensed-ExtraBoldItalic",
    displayName: "Fira Sans",
    style: "font-fira-sans",
    previewText: "Abc"
  },
  { 
    name: "Gabarito-Black",
    displayName: "Gabarito",
    style: "font-gabarito",
    previewText: "Abc"
  },
  { 
    name: "KOMIKAX_",
    displayName: "Komika",
    style: "font-komikax",
    previewText: "Abc"
  },
  { 
    name: "Montserrat-Bold",
    displayName: "Montserrat",
    style: "font-montserrat",
    previewText: "Abc"
  },
  { 
    name: "Proxima-Nova-Semibold",
    displayName: "Proxima",
    style: "font-proxima-nova",
    previewText: "Abc"
  },
  { 
    name: "Rubik-ExtraBold",
    displayName: "Rubik",
    style: "font-rubik",
    previewText: "Abc"
  }
];

const SubtitleFontSelector: React.FC<SubtitleFontSelectorProps> = ({ currentFont, onFontChange }) => {
  return (
    <div className="flex flex-col space-y-2">
      <label className="text-white text-sm font-medium">Font Style</label>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2">
        {fontOptions.map((font) => (
          <button
            key={font.name}
            onClick={() => onFontChange(font.name)}
            className={`
              aspect-[4/3] p-2 border-2 rounded-md flex flex-col items-center justify-center
              ${currentFont === font.name 
                ? 'border-blue-500 bg-blue-500 bg-opacity-20' 
                : 'border-gray-600 hover:border-gray-400'}
              transition-colors duration-200 ease-in-out
              focus:outline-none
              min-w-0
            `}
          >
            <div className={`${font.style} text-white text-center w-full truncate`}>
              <span className="text-lg block">{font.previewText}</span>
              <p className="text-xs mt-1 font-normal opacity-70 truncate px-1">
                {font.displayName}
              </p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SubtitleFontSelector;