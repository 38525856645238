import React from 'react';

interface SubtitleCensorSelectorProps {
  currentCensorStatus: boolean;
  onCensorChange: (status: boolean) => void;
}

const SubtitleCensorSelector: React.FC<SubtitleCensorSelectorProps> = ({ 
  currentCensorStatus, 
  onCensorChange 
}) => {
  return (
    <div className="flex flex-col space-y-2">
      <label className="text-white text-sm font-medium">Text Censorship</label>
      <div className="grid grid-cols-2 gap-2 max-w-[240px]">
        <button
          onClick={() => onCensorChange(true)}
          className={`
            group relative p-3 border-2 rounded-md
            transition-all duration-200 ease-in-out
            ${currentCensorStatus
              ? 'border-blue-500 bg-blue-500 bg-opacity-20'
              : 'border-gray-600 hover:border-gray-400'
            }
            focus:outline-none
          `}
        >
          <div className="text-white text-center">
            <div className="flex items-center justify-center mb-1">
              <svg 
                className={`w-5 h-5 ${currentCensorStatus ? 'text-blue-400' : 'text-gray-400'}`}
                fill="none" 
                strokeWidth="2"
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" 
                />
              </svg>
            </div>
            <span className={`text-sm ${currentCensorStatus ? 'text-blue-400' : 'text-gray-400'}`}>
              Enabled
            </span>
          </div>
        </button>

        <button
          onClick={() => onCensorChange(false)}
          className={`
            group relative p-3 border-2 rounded-md
            transition-all duration-200 ease-in-out
            ${!currentCensorStatus
              ? 'border-blue-500 bg-blue-500 bg-opacity-20'
              : 'border-gray-600 hover:border-gray-400'
            }
            focus:outline-none
          `}
        >
          <div className="text-white text-center">
            <div className="flex items-center justify-center mb-1">
              <svg 
                className={`w-5 h-5 ${!currentCensorStatus ? 'text-blue-400' : 'text-gray-400'}`}
                fill="none" 
                strokeWidth="2"
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" 
                />
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" 
                />
              </svg>
            </div>
            <span className={`text-sm ${!currentCensorStatus ? 'text-blue-400' : 'text-gray-400'}`}>
              Disabled
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SubtitleCensorSelector;