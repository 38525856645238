// PopupForm.tsx

import React, { useState, useEffect } from 'react';
import ImageWithBoxes from './ImageWithBoxes';
import SubtitleColorSelector from './SubtitleColorSelector';
import LoadingBar from './LoadingBar';
import SubtitleFontSelector from './SubtitleFontSelector';
import SubtitleCensorSelector from './SubtitleCensorSelector';

interface PopupFormProps {
  isVisible: boolean;
  onClose: () => void;
  imageData: any;
  isFacecam: boolean;
  onFacecamToggle: (newState: boolean) => void;
  onFaceBoxChange: (newBox: number[]) => void;
  onGameplayBoxChange: (newBox: number[]) => void;
  initialTextColor: string;
  initialCensorSubtitles: boolean;
  initialFontName: string;
  handleSubmit: (
    faceBox: number[],
    gameplayBox: number[],
    isFacecam: boolean,
    textColor: string,
    censorSubtitles: boolean,
    fontName: string
  ) => void;
  faceBox: number[] | null;
  gameplayBox: number[] | null;
  editorMode?: boolean; // New prop to determine if we're in editor mode
}

const PopupForm: React.FC<PopupFormProps> = ({
  isVisible,
  onClose,
  imageData,
  isFacecam,
  onFacecamToggle,
  onFaceBoxChange,
  onGameplayBoxChange,
  initialTextColor,
  initialCensorSubtitles,
  initialFontName,
  handleSubmit,
  faceBox,
  gameplayBox,
  editorMode = false // Default to false for backward compatibility
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [textColor, setTextColor] = useState<string>(initialTextColor);
  const [censorSubtitles, setCensorSubtitles] = useState<boolean>(initialCensorSubtitles);
  const [fontName, setFontName] = useState<string>(initialFontName);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isFacecamValueAssigned, setIsFacecamValueAssigned] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    if (imageData) {
      setIsLoadingComplete(true);
      const timer = setTimeout(() => {
        setIsImageLoaded(true);
        if (imageData) {
          setIsFacecamValueAssigned(true);
        }
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [imageData]);

  if (!isVisible) return null;

  const onSubmit = () => {
    handleSubmit(
      faceBox || imageData.face_coordinates,
      gameplayBox || imageData.gameplay_coordinates,
      isFacecam,
      textColor,
      censorSubtitles,
      fontName
    );
  };

  const renderContent = () => (
    <>
      <p className="mb-4 font-bold italic text-white text-center text-sm sm:text-base">
        Adjust the <span className="text-red-500">facecam</span> & <span className="text-blue-500">gameplay</span> boxes, and submit!
      </p>
      {!isImageLoaded ? (
        <div className="w-full max-w-md mx-auto">
          <LoadingBar isComplete={isLoadingComplete} />
        </div>
      ) : (
        <div className="w-full max-w-3xl mx-auto">
          <ImageWithBoxes
            imageUrl={imageData.frame}
            facecamStatus={isFacecam}
            onFacecamToggle={onFacecamToggle}
            faceCoordinates={faceBox || imageData.face_coordinates}
            gameplayCoordinates={gameplayBox || imageData.gameplay_coordinates}
            onFaceBoxChange={onFaceBoxChange}
            onGameplayBoxChange={onGameplayBoxChange}
            onImageLoad={() => setIsImageLoaded(true)}
          />
        </div>
      )}
      <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
        <div className="flex items-center w-full sm:w-auto">
          {isFacecamValueAssigned && (
            <div className="flex rounded-md bg-gray-700 p-0.5 w-full sm:w-auto">
              <button
                onClick={() => onFacecamToggle(true)}
                className={`flex-1 sm:flex-none px-4 py-2 rounded-md transition-all duration-200 ease-in-out text-sm sm:text-base ${
                  isFacecam
                    ? 'bg-white text-black font-semibold outline outline-2 outline-white'
                    : 'text-white hover:bg-gray-600'
                }`}
              >
                Facecam On
              </button>
              <button
                onClick={() => onFacecamToggle(false)}
                className={`flex-1 sm:flex-none px-4 py-2 rounded-md transition-all duration-200 ease-in-out text-sm sm:text-base ${
                  !isFacecam
                    ? 'bg-white text-black font-semibold outline outline-2 outline-white'
                    : 'text-white hover:bg-gray-600'
                }`}
              >
                Facecam Off
              </button>
            </div>
          )}
        </div>
        {editorMode ? (
          <button
            onClick={onSubmit}
            className={`w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded transition-colors text-sm sm:text-base ${
              isImageLoaded ? 'hover:bg-blue-600' : 'opacity-50 cursor-not-allowed'
            }`}
            disabled={!isImageLoaded}
          >
            Apply Changes
          </button>
        ) : (
          <button
            onClick={() => setCurrentPage(2)}
            className={`w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded transition-colors text-sm sm:text-base ${
              isImageLoaded ? 'hover:bg-blue-600' : 'opacity-50 cursor-not-allowed'
            }`}
            disabled={!isImageLoaded}
          >
            Next &gt;
          </button>
        )}
      </div>
    </>
  );

  const renderPage2 = () => (
    <>
      <h2 className="text-lg sm:text-xl font-bold mb-4 text-white">Customize your highlight</h2>
      <div className="space-y-4">
        <SubtitleColorSelector
          currentColor={textColor}
          onColorChange={setTextColor}
        />
        <SubtitleCensorSelector
          currentCensorStatus={censorSubtitles}
          onCensorChange={setCensorSubtitles}
        />
        <SubtitleFontSelector
          currentFont={fontName}
          onFontChange={setFontName}
        />
      </div>
      <div className="mt-4 flex flex-col sm:flex-row justify-between space-y-2 sm:space-y-0">
        <button
          onClick={() => setCurrentPage(1)}
          className="w-full sm:w-auto bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-colors text-sm sm:text-base"
        >
          &lt; Back
        </button>
        <button
          onClick={onSubmit}
          className="w-full sm:w-auto bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors text-sm sm:text-base"
        >
          Submit
        </button>
      </div>
    </>
  );

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="relative bg-[#171717] p-4 sm:p-8 rounded-lg shadow-lg w-full max-w-4xl border border-white border-opacity-20">
        <button
          className="absolute top-2 right-2 text-gray-400 hover:text-white transition-colors text-xl sm:text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        {editorMode ? renderContent() : (currentPage === 1 ? renderContent() : renderPage2())}
      </div>
    </div>
  );
};

export default PopupForm;